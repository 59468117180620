import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import './GameBody.style.css'
import { initCloudStorage, retrieveLaunchParams, useHapticFeedback } from "@telegram-apps/sdk-react";

import { IGameButton, IGameButtonOnField } from "../game/interfaces";
import axios from "axios";
import { API_URL } from "../api/hooks";
import { ButtonOnField } from "./ButtonOnField";
import { motion } from "framer-motion";



interface IGameBodyProps {
    handleTap: (button: IGameButton) => void
    currentLevelNumber: number
}


export const GameBody = ({ handleTap, currentLevelNumber }: IGameBodyProps) => {
    const refField = useRef<HTMLDivElement>(null);
    const [refWith, setRefWith] = useState(10);
    const [refHeight, setRefHeight] = useState(10);

    const [buttons, setButtons] = useState<Array<IGameButton>>();

    const cloudStorage = initCloudStorage();

    const GetRandomPosition = useCallback((): { x: number; y: number } => {
        const x = Math.max(10, Math.floor(Math.random() * (refWith ?? window.innerWidth)) - 30);
        const y = Math.max(10, Math.floor(Math.random() * (refHeight ?? window.innerHeight)) - 70);

        return {
            x,
            y,
        };
    }, [refWith, refHeight]);

    const GetNewButton = useCallback(() => {
        if (buttons) {
            const randomNumber = Math.random() * 100;
            const totalRarity = buttons.reduce((acc, curr) => acc + curr.rarity, 0);
            let currentRarity = 0;
            for (let i = 0; i < buttons.length; i++) {
                currentRarity += buttons[i].rarity;
                if (randomNumber < currentRarity / totalRarity * 100) {
                    return buttons[i];
                }
            }
            return buttons[buttons.length - 1];
        } else {
            return {
                name: 'MainSausage',
                rarity: -1,
                score: 1,
                color: 'red',
                id: 'error',
            } as IGameButton;
        }
    }, [buttons]);

    const MAX_BUTTONS_ON_FIELD = -20;

    const [MinusButtonsOnField, setMinusButtonsOnField] = useState<Array<IGameButtonOnField>>([]);
    const [BonusButtonsOnField, setBonusButtonsOnField] = useState<Array<IGameButtonOnField>>([]);

    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    const token = `tma ${initDataRaw}`;

    const hapticFeedback = useHapticFeedback();

    const handleButtonClick = useCallback((button: IGameButton) => {
        handleTap(button);
        hapticFeedback.notificationOccurred(button.score > 0 ? 'success' : 'error');

        setBonusButtonsOnField((btns) => {
            return btns.map((btn) => {
                if (btn.button.score > 0) {
                    return {
                        ...btn,
                        position: GetRandomPosition(),
                        handleClick: handleButtonClick
                    } as IGameButtonOnField
                } else {
                    return btn
                }
            })
        })
        // if (button.score > 1) {
        //     setButtonsOnField((buttonsOnField) => {
        //         return buttonsOnField.filter((buttonOnField) => buttonOnField.button.score <= 1)
        //     })
        // }
        // setButtonsOnField((buttonsOnField) => {
        //     return [
        //         ...buttonsOnField,
        //         {
        //             button: GetNewButton(),
        //             position:GetRandomPosition(),
        //             createdAt: Date.now(),
        //             handleClick: handleButtonClick
        //         }
        //     ]
        // })
        // setButtonsOnField((buttonsOnField) => {
        //     return buttonsOnField.slice(MAX_BUTTONS_ON_FIELD + 1);
        // })
    }, [GetRandomPosition, handleTap, hapticFeedback]);

    // useEffect(() => {
    //     cloudStorage.get('buttonsOnField').then((bnts) => {
    //         setMinusButtonsOnField((_prev) => {
    //             const loaded = JSON.parse(bnts || '[]')
    //             const result = loaded.map((bnt: any) => {
    //                 return {
    //                     ...bnt,
    //                     handleClick: handleButtonClick,
    //                 }
    //             }) as Array<IGameButtonOnField>;

    //             return result
    //         })
    //     })
    // }, []);

    useEffect(() => {
        if (!buttons) {
            console.log('Fetching buttons...')
            axios.get<Array<IGameButton>>(
                `${API_URL}buttons`,
            ).then((response) => {
                setButtons(response.data)
            }).catch((error) => {
                console.error('Error fetching buttons:', error)
            })
        }
    }, [buttons, cloudStorage, handleButtonClick, token]);


    const lastDtNegative2 = useRef(Date.now() + 3000);
    const lastDtNegative3 = useRef(Date.now() + 10000);

    useEffect(() => {
        const interval = setInterval(() => {
            if (buttons && BonusButtonsOnField) {
                let newBonusButtons = BonusButtonsOnField.slice();
                const IsNegative1OnField = newBonusButtons.some((btn) => btn.button.rarity === -1);

                if (!IsNegative1OnField) {
                    newBonusButtons.push({
                        button: buttons.find((btn) => btn.rarity === -1) as IGameButton,
                        position: GetRandomPosition(),
                        createdAt: Date.now(),
                        handleClick: handleButtonClick
                    })
                } else {
                    newBonusButtons.map((btn) => {
                        if (btn.button.rarity === -1) {
                            btn.handleClick = handleButtonClick;
                        }
                    })
                }

                if (lastDtNegative2.current === 0) {
                    newBonusButtons.push({
                        button: buttons.find((btn) => btn.rarity === -2) as IGameButton,
                        position: GetRandomPosition(),
                        createdAt: Date.now(),
                        handleClick: handleButtonClick
                    })
                    lastDtNegative2.current = Date.now();
                    // hapticFeedback.impactOccurred('soft')
                } else if (Date.now() - lastDtNegative2.current > 10000) {
                    newBonusButtons.push({
                        button: buttons.find((btn) => btn.rarity === -2) as IGameButton,
                        position: GetRandomPosition(),
                        createdAt: Date.now(),
                        handleClick: handleButtonClick
                    })
                    lastDtNegative2.current = Date.now();
                    // hapticFeedback.impactOccurred('soft')
                }


                if (lastDtNegative3.current === 0) {
                    newBonusButtons.push({
                        button: buttons.find((btn) => btn.rarity === -3) as IGameButton,
                        position: GetRandomPosition(),
                        createdAt: Date.now(),
                        handleClick: handleButtonClick
                    })
                    // hapticFeedback.impactOccurred('heavy')
                    lastDtNegative3.current = Date.now();
                } else if (Date.now() - lastDtNegative3.current > 60000) {
                    newBonusButtons.push({
                        button: buttons.find((btn) => btn.rarity === -3) as IGameButton,
                        position: GetRandomPosition(),
                        createdAt: Date.now(),
                        handleClick: handleButtonClick
                    })
                    // hapticFeedback.impactOccurred('heavy')
                    lastDtNegative3.current = Date.now();
                }
                setBonusButtonsOnField(newBonusButtons);
                setBonusButtonsOnField((prev) => {
                    return prev.filter((btn) => {
                        if (btn.button.rarity === -2 || btn.button.rarity === -3) {
                            console.log(Date.now() - btn.createdAt)
                            if ((Date.now() - btn.createdAt) > 2000) {
                                return false;
                            }
                            return true;
                        } else {
                            return true;
                        }
                    })
                })
                setBonusButtonsOnField((prev) => {
                    return prev.map((btn) => {
                        btn.position = GetRandomPosition();
                        return btn
                    })
                })
            }
        }, 1210 - (currentLevelNumber * 10));
        return () => clearInterval(interval)
    }, [buttons, currentLevelNumber]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (buttons && MinusButtonsOnField) {

                setMinusButtonsOnField((prev) => {
                    if (prev.length >= Math.abs(MAX_BUTTONS_ON_FIELD)) {
                        const result = prev.slice(MAX_BUTTONS_ON_FIELD - 1)
                        // cloudStorage.set('buttonsOnField', JSON.stringify(result));
                        return result.map((btn) => {
                            btn.position = GetRandomPosition();
                            return btn
                        })
                    } else {
                        let newMinusButtons = prev.filter((buttonOnField) => buttonOnField.button.rarity > 0);
                        newMinusButtons.push({
                            button: GetNewButton(),
                            position: GetRandomPosition(),
                            createdAt: Date.now(),
                            handleClick: handleButtonClick
                        })
                        return newMinusButtons.map((btn) => {
                            btn.position = GetRandomPosition();
                            return btn
                        })
                    }
                });
            }

        }, 1210 - (currentLevelNumber * 10));
        return () => clearInterval(interval)
    }, [buttons, currentLevelNumber]);

    useEffect(() => {
        if (refField.current) {
            setRefWith(refField.current.offsetWidth);
            setRefHeight(refField.current.offsetHeight);
        }
    }, [refField]);

    return (
        <motion.div className="transform-gpu grow m-3 relative transition-all duration-300 ease-in-out">
            <motion.div ref={refField} className="field h-full will-change-transform">
                {Array(100).fill(null).map((_, index) => (
                    <motion.div key={index} className="field-item w-3 h-3 border-r border-b border-b-gray-600 border-r-gray-600 bg-[#181c25] rounded-full shadow-inner relative m-auto -z-20"></motion.div>
                ))}
                <motion.div className=""></motion.div>
                <motion.div className=""></motion.div>
                <motion.div className=""></motion.div>
            </motion.div>
            <motion.div className="will-change-[opacity,transform] absolute top-[20%] right-[5%] w-2/5 h-2/5 -z-10 bg-blur opacity-30 rounded-full blur-3xl animate-[ping_2.5s_1.5s_infinite]"></motion.div>
            <motion.div className="will-change-[opacity,transform] absolute top-[50%] right-[30%] w-3/5 h-3/5 -z-10 bg-blur opacity-30 rounded-full blur-3xl animate-[ping_2s_1s_infinite]"></motion.div>
            {[...BonusButtonsOnField, ...MinusButtonsOnField].map((button, index) => (
                <ButtonOnField key={index} position={button.position} button={button.button} createdAt={button.createdAt} handleClick={button.handleClick} />
            ))}
        </motion.div>
    );
};

