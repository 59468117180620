import { initMiniApp, postEvent, retrieveLaunchParams, useBackButton, useHapticFeedback } from "@telegram-apps/sdk-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "../api/hooks";
import { IEventsState } from "../api/interfaces";
import TON from "../assets/images/TON.webp";

import MsNotLefetElement from "../assets/images/ms_not_left.webp";
import MsLeftElement from "../assets/images/ms_left_elem.webp"

import About from "../assets/images/buttons/about.png";
import Characters from "../assets/images/buttons/characters.png";
import Levels from "../assets/images/buttons/levels.png";
import Invites from "../assets/images/buttons/invites.png";
import Leaders from "../assets/images/buttons/leaders.png";
import Past from "../assets/images/buttons/past.png";

import TonLogo from "../assets/images/ton_symbol.svg";
import { motion } from "framer-motion";


import './menu.styles.css'


export const MenuPage = () => {

    const { t } = useTranslation();

    const backButton = useBackButton();
    const hapticFeedback = useHapticFeedback();
    const navigate = useNavigate();

    const [miniApp] = initMiniApp();

    const sausage = useRef<HTMLImageElement>(null);
    const [rightOffset, setRightOffset] = useState(0);

    const [width, setWidth] = useState(0);

    useEffect(() => {
        miniApp.setHeaderColor('#1e222c');
    }, []);

    useEffect(() => {
        if (sausage.current) {
            const dmrect = sausage.current.getBoundingClientRect();
            setRightOffset(dmrect.left);
            if (dmrect.width > 45) {
                setWidth(41)
            }

            else {
                setWidth(dmrect.width);
            }

            console.log('Right offset: ', dmrect.left)
            console.log('Width: ', dmrect.width)
        }
    }, [sausage]);

    useEffect(() => {
        backButton.show();
        backButton.on('click', () => {
            hapticFeedback?.notificationOccurred('success');
            navigate('/game')
            backButton.hide();
        })
    }, []);

    const [eventState, setEventState] = useState<IEventsState>();
    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    const token = `tma ${initDataRaw}`;

    useEffect(() => {
        if (!eventState) {
            axios.get<IEventsState>(
                `${API_URL}events/state`,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((response) => {
                setEventState(response.data)
                console.log(response.data)
            }).catch((error) => {
                throw error
            })
        }
    }, [eventState, token]);

    return (
        <>
            <div className="transform-gpu h-screen w-screen flex flex-col ease-in-out delay-300 transition-all">
                <div className="grow flex items-center px-8">
                    <div className="relative">
                        <div className="will-change-[opacity] absolute top-[50%] left-[30%] w-3/5 h-3/5 z-0 bg-blur opacity-60 rounded-full blur-3xl animate-[ping_2s_3s_infinite]"></div>
                        <motion.img className="ml-auto w-[2.945rem] h-[2.945rem] -rotate-12 mb-[0.75rem]" src={TON} alt="" />
                        <motion.h1 className='text-xl font-video text-center tracking-wide font-bold text-border-progress uppercase flex'>Crazy<div className="text-add-coins ml-2">Sausage</div></motion.h1>
                        <div className="flex items-center justify-center text-sm font-extralight mt-1">
                            <motion.code className="opacity-60">{t('MenuPage.title')}</motion.code>
                        </div>
                    </div>
                </div>
                <div className="relative h-[70%]">
                    {/* <img className="absolute top-[-16rem] left-0 right-[-15rem] z-10 opacity-95" src={Blur} alt="Blur" />
                    <img className="absolute top-[-9rem] left-0 right-0 z-10 opacity-2" src={Blur} alt="Blur" /> */}
                    {rightOffset !== 0 &&
                        <motion.img src={MsLeftElement} alt="Sausage" className={`rotate-[-10deg] absolute h-[4rem] top-[-2.9rem]  z-10`} style={{ right: `${rightOffset - width - 57}px` }} />
                    }
                    <motion.img className="absolute w-[2rem] h-[2rem] rotate-12 top-[-10.9rem] right-[7rem]" src={TON} alt="" />
                    <div className="absolute top-[-9rem] right-[-15rem] w-full h-full overflow-hidden z-1">
                        <motion.img ref={sausage} src={MsNotLefetElement} alt="Sausage" className={`h-[15rem] rotate-[-10deg] ms-not-left`} />
                    </div>
                    <div className="will-change-[opacity] absolute top-[50%] left-[30%] w-3/5 h-3/5 z-15 bg-blur opacity-60 rounded-full blur-3xl animate-[ping_1s_3s_infinite]"></div>
                    <div className="absolute top-0 left-0 right-0 bottom-0 border-t-2 border-white/30 rounded-t-3xl bg-gradient-to-b from-bg-gradient-1 to-bg-gradient-2 mt-auto transition-all duration-300 ease-in-out z-2 overflow-y-scroll">

                        <motion.menu type="toolbar" className="flex flex-col items-center mx-3 first:mt-4 text-sm font-video tracking-wide font-medium text-border-progress relative scroll-smooth">
                            <div className="will-change-[opacity] absolute top-[50%] left-[30%] w-3/5 h-3/5 z-0 bg-blur opacity-60 rounded-full blur-3xl animate-[ping_2s_3s_infinite]"></div>
                            <div className="will-change-[opacity] absolute top-[10%] right-[10%] w-3/5 h-3/5 z-0 bg-blur opacity-60 rounded-full blur-3xl animate-[ping_2s_3s_infinite]"></div>
                            {/* <div className="menu-button" onClick={() => {
                                hapticFeedback?.notificationOccurred('success');
                                postEvent('shareToStory', {
                                    media_url: require('../assets/images/share.png')
                                })
                            }}>
                                {t('MenuPage.story')}
                            </div> */}
                            <div className="menu-button" onClick={() => {
                                hapticFeedback?.notificationOccurred('success');
                                navigate('/about')
                            }}>
                                <img className="w-[2.065rem] h-[2.065rem] mr-2" src={About} alt="About" />
                                {t('MenuPage.about')}
                            </div>
                            <div className="menu-button" onClick={() => {
                                hapticFeedback?.notificationOccurred('success'); navigate('/characters')
                            }}>
                                <img className="w-[2.065rem] h-[2.065rem] mr-2" src={Characters} alt="Characters" />
                                {t('MenuPage.characters')}
                            </div>
                            <div className="menu-button" onClick={() => {
                                hapticFeedback?.notificationOccurred('success'); navigate('/levels')
                            }}>
                                <img className="w-[2.065rem] h-[2.065rem] mr-2" src={Levels} alt="Levels" />
                                {t('MenuPage.levels')}
                            </div>
                            <div className="menu-button" onClick={() => {
                                hapticFeedback?.notificationOccurred('success'); navigate('/invites')
                            }}>
                                <img className="w-[2.065rem] h-[2.065rem] mr-2" src={Invites} alt="Invites" />
                                {t('MenuPage.invites')}
                            </div>
                            <div className="menu-button" onClick={() => {
                                hapticFeedback?.notificationOccurred('success');
                                navigate('/leaders')
                            }}>
                                <img className="w-[2.065rem] h-[2.065rem] mr-2" src={Leaders} alt="Leaders" />
                                {t('MenuPage.leaders')}
                                <div className="flex ml-auto">
                                    {Array.from({ length: 5 }).map((_, i) => (
                                        <img
                                            className="h-[0.929rem] -rotate-12"
                                            src={TON}
                                            alt="TON"
                                        />
                                    ))}
                                </div>

                            </div>
                            {/* <div className={`menu-button ${!eventState?.prev_event ? 'disabled-menu' : ''}`} onClick={() => {
                                hapticFeedback?.notificationOccurred('success'); navigate('/past')
                            }}> */}
                            <div className={`menu-button disabled-menu`} onClick={() => {
                                hapticFeedback?.notificationOccurred('success'); navigate('/past')
                            }}>
                                <img className="w-[2.065rem] h-[2.065rem] mr-2" src={Past} alt="Past" />
                                {t('MenuPage.past')}
                                <div className="flex ml-auto flex-col items-end">
                                    <div className="text-sm font-video text-center text-add-coins text-nowrap">
                                        {t('MenuPage.pastDesc')}
                                    </div>
                                    <div className="flex items-center">
                                        <img
                                            className="h-[0.941rem]"
                                            src={TonLogo}
                                            alt="Ton logo"
                                        />
                                        <div className="ton-1 uppercase ml-[0.305rem]">{!eventState?.prev_event ? '0' : 25} TON</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex items-center w-full justify-center my-4">

                                <code className="text-[1.063rem] ml-4 text-center text-border-progress font-extralight">Crazy Sausage - забери свои TONs</code>
                                <img src={TonLogo} className="h-[0.941rem] ml-2" alt="" />
                            </div> */}
                        </motion.menu>
                    </div>
                </div>

            </div>

        </>

    );
}