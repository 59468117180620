import { initMiniApp, postEvent, retrieveLaunchParams, useBackButton } from "@telegram-apps/sdk-react";
import { useEffect, useMemo, useState } from "react";

import './loading.styles.css'
import { useNavigate } from 'react-router-dom';
import MainSausage from "../assets/images/MainSausage.webp";
import TonLogo from "../assets/images/ton_symbol.svg";
import Star from "../assets/images/Star.webp";
import TON from "../assets/images/TON.webp";
import { AddCoinElement } from "../components/UI/AddCoin";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "../api/hooks";
import { IGameButton } from "../game/interfaces";
import { motion } from "framer-motion";


export const LoadingPage = () => {
    const { t, i18n } = useTranslation();
    const [miniApp] = initMiniApp();
    const navigate = useNavigate();
    const backButton = useBackButton();
    const {initData} = retrieveLaunchParams();

    const [addCoinsStack, setAddCoinsStack] = useState<Array<number>>([]);
    const [hasPing, setHasPing] = useState<boolean>(false);

    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    const token = `tma ${initDataRaw}`;

    useEffect(() => {
        if (!hasPing) {
            axios.get<Array<IGameButton>>(
                `${API_URL}ping`,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((response) => {
                setHasPing(true);
            }).catch((error) => {
                throw error
            })
        }
    }, [hasPing, token]);

    useEffect(() => {
        miniApp.setHeaderColor('#131822')
        miniApp.setBgColor('#1d212a');
        backButton.hide();
        postEvent('web_app_expand');
        miniApp.ready();
    }, []);

    useEffect(() => {
        if (initData) {
            if (initData.user?.languageCode === 'ru') {
                i18n.changeLanguage('ru');
            } else {
                i18n.changeLanguage('en');
            }
        }
    }, [i18n, initData]);

    useEffect(() => {
        const addCoin = () => {
            const newElements = Array.from({ length: 1 }, () => Math.random() * 1000);
            setAddCoinsStack(prev => [...newElements]);
        };
        const intervalId = setInterval(addCoin, 2000);
        addCoin();
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/game');
        }, 3000);
        return () => clearTimeout(timer);
    }, [navigate])

    return (
        <div className="grow background flex flex-col justify-center items-center">
            <div className='ease-in-out'>
                <motion.img
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    decoding="async"
                    fetchPriority="high"
                    src={Star}
                    alt="Star"
                    className='absolute transition-all duration-300 ease-in-out top-[90px] left-[60px] rotate-1'
                    height={'30px'}
                    width={'30px'} />
                <motion.img decoding="async" fetchPriority="high" src={Star}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    alt="Star" className='absolute transition-all duration-300 ease-in-out top-[100px] right-[75px] rotate-3' height={'25px'} width={'25px'} />
                <motion.img decoding="async" fetchPriority="high" src={TON}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    alt="TON" className='absolute transition-all duration-300 ease-in-out top-[420px] right-[20px] rotate-[-5deg]' height={'40px'} width={'40px'} />
                <motion.img decoding="async" fetchPriority="high" src={Star}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    alt="Star" className='absolute transition-all duration-300 ease-in-out top-[380px] left-[40px] rotate-6' height={'35px'} width={'35px'} />
                {/* <div className="transform-gpu">
                    <ul>
                        <li></li>
                        <li></li>
                    </ul>
                </div> */}
            </div>
            <div className="fixed z-40 top-[5%] left[40%] flex flex-col-reverse items-center">
                {
                    addCoinsStack.map((value, index) => (
                        <AddCoinElement style={
                            {
                                animationIterationCount: 'infinite',
                            }
                        } key={index} number={value} />
                    ))
                }

            </div>
            <img decoding="async" fetchPriority="high" src={MainSausage} className='will-change-transform transition-all duration-300 ease-in-out h-3/5 animate-sasuage [animation-timing-function:_cubic-bezier(0.1, 0.55, 0.5, 1, 0.45, 0.9, 0.4, 1)]' alt="Main sausage" />
            <div className="w-full flex flex-col items-center justify-center font-main font-bold bg-clip-text text-6xl my-5 z-1">
                <h1 className='text-4xl font-video text-center tracking-wide font-bold text-border-progress uppercase flex'>Crazy<div className="text-add-coins ml-2">Sausage</div></h1>
                <div className="flex items-center justify-center text-sm font-extralight mt-1">
                    <code className="opacity-60">{t('LoadingPage.ton')} TONs</code>
                    <img decoding="auto" fetchPriority="auto" src={TonLogo} alt="Ton logo" width={'25px'} height={'25px'} className={'ml-2'} />
                </div>
            </div>
            {/* <div className="transform-gpu">
                <div className="will-change-[opacity] absolute top-[50%] right-[25%] w-3/5 h-3/5 -z-10 bg-blur opacity-90 rounded-full animate-pulse blur-3xl"></div>
            </div> */}
        </div>
    );
}