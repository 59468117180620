import { Temporal } from "@js-temporal/polyfill";
import { useEffect, useState } from "react";


interface ICountDownTimerProps {
    endDtEvent: Temporal.ZonedDateTime
}


export const CountDownTimer = ({ endDtEvent }: ICountDownTimerProps) => {
    const [now, setNow] = useState<Temporal.ZonedDateTime>(Temporal.Now.zonedDateTimeISO());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setNow(Temporal.Now.zonedDateTimeISO());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const diff = endDtEvent.since(now);

    const hours = diff.hours;
    const minutes = diff.minutes;
    const seconds = diff.seconds;

    return (
        <div>
            {`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
        </div>
    )
};
