import { IGameButtonOnField } from "../game/interfaces"


export const ButtonOnField = ({ position, button, handleClick }: IGameButtonOnField) => {

    const styles = {
        transform: `translateY(${position.y}px) translateX(${position.x}px)`,
    };

    return (

        <>
            {button.score < 0 &&
                <img
                    src={require(`../assets/images/characters/${button.name}.webp`)}
                    onTouchStart={() => handleClick(button, position)}
                    className={`will-change-[transform] absolute top-0 left-0 transition-all duration-300 ease-in-out h-[60px] cursor-pointer`}
                    style={styles as React.CSSProperties}
                    alt={button.name}
                />
            }
            {button.score > 0 &&
                <img
                    src={require(`../assets/images/characters/${button.name}.webp`)}
                    onTouchStart={() => handleClick(button, position)}
                    className={`will-change-[transform] absolute top-0 left-0 transition-all duration-300 ease-in-out h-[75px] cursor-pointer z-20`}
                    style={styles as React.CSSProperties}
                    alt={button.name}
                />
            }
        </>
    )
} 
