import { useTranslation } from "react-i18next";

export const SettingsPage = () => {

    const { t } = useTranslation();


    return (
        <div className="flex flex-col items-center justify-center mt-8">
            <h1 className="text-3xl font-bold text-center">
                {t('settings.title')}
            </h1>
        </div>
    );
}