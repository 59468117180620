import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            'GamePage.needSubscribeTitle': 'Subscribe to the channel',
            'GamePage.needSubscribeMessage': 'To participate in the tournament, you need to subscribe to the channel: ',
            'About.givePointsPopupTitle': 'Give points',
            'About.givePointsPopupMessage': 'points.',
            'About.delPointsPopupTitle': 'Delete points',
            'GameHead.levelTitle': 'Level',
            'GameHead.levelMessage': 'Your level now',
            'GameHead.pointsTitle': 'Points',
            'GameHead.pointsMessage': 'Your points in this tournament',
            'LoadingPage.ton': 'Tournament for',
            'MenuPage.title': 'Be the best in the tournament',
            'MenuPage.story': 'Share in story',
            'MenuPage.about': 'About the tournament',
            'MenuPage.characters': 'Characters',
            'MenuPage.levels': 'Levels',
            'MenuPage.invites': 'Invites',
            'MenuPage.leaders': 'Leaders',
            'MenuPage.past': 'Past tournament',
            'MenuPage.pastDesc': 'Prize pool',
            'CharacterPage.character': 'Character',
            'CharacterPage.scores': 'Scores',
            'CharacterPage.rarity': 'Rarity',
            'CharacterPage.per': 'per',
            'LevelsPage.level': 'Level',
            'LevelsPage.score': 'Score',
            'LevelsPage.bonus': 'Bonus',
            'error.title': 'Error',
            'error.message': 'Something went wrong',
            'error.offline': 'No internet connection',
            'About.Title': 'Win tournament',
            'About.Text1': 'Click on the buttons',
            'About.Text2': ' and get ',
            'About.Text3': 'points',
            'About.Text4': 'minus monsters',
            'About.Text5': 'But avoid the',
            'About.Text6': ', they take away your points',
            'About.Text7': 'Invite friends',
            'About.Text8': 'points for you',
            'InviteFriends': 'Invite friends',
            'LeaderPage.prizePool': 'Prize pool',
            'RefferalsPage.title': 'Invite friends',
            'RefferalsPage.invites': 'users',
            'RefferalsPage.description': 'You earned',
            'RefferalsPage.noInvites': 'No invites',
            'really.title': 'Play on your mobile phone',
        }
    },
    ru: {
        translation: {
            'GamePage.needSubscribeTitle': 'Подпишитесь на канал',
            'GamePage.needSubscribeMessage': 'Для участия в игре необходимо подписаться на канал \n',
            'About.givePointsPopupTitle': 'Даёт',
            'About.givePointsPopupMessage': 'очков.',
            'About.delPointsPopupTitle': 'Отнимает',
            'LoadingPage.ton': 'Турнир за',
            'GameHead.levelTitle': 'Уровень',
            'GameHead.levelMessage': 'Ваш уровень на текущий момент',
            'GameHead.pointsTitle': 'Очки',
            'GameHead.pointsMessage': 'Ваши очки в текущем турнире',
            'MenuPage.title': 'Будь лучшим в турнире',
            'MenuPage.story': 'Поделиться в истории',
            'MenuPage.about': 'О турнире',
            'MenuPage.characters': 'Персонажи',
            'MenuPage.levels': 'Уровни',
            'MenuPage.invites': 'Приглашения',
            'MenuPage.leaders': 'Таблица лидеров',
            'MenuPage.past': 'Прошедший турнир',
            'MenuPage.pastDesc': 'Призовой фонд',
            'CharacterPage.character': 'Персонаж',
            'CharacterPage.scores': 'Очки',
            'CharacterPage.rarity': 'Вероятность',
            'CharacterPage.per': 'в',
            'LevelsPage.level': 'Уровень',
            'LevelsPage.score': 'Очки',
            'LevelsPage.bonus': 'Бонус',
            'error.title': 'Ошибка',
            'error.message': 'Что-то пошло не так',
            'error.offline': 'Отсутствует сеть',
            'About.Title': 'Выиграй турнир',
            'About.Text1': 'Жми по сосискам',
            'About.Text2': ' и получай ',
            'About.Text3': 'очки',
            'About.Text4': 'минусовых монстров',
            'About.Text5': 'Но избегай',
            'About.Text6': ', они отнимают твои очки',
            'About.Text7': 'Пригласи друга',
            'About.Text8': 'очков для тебя',
            'InviteFriends': 'Пригласить друга',
            'LeaderPage.prizePool': 'Призовой фонд',
            'RefferalsPage.title': 'Приглашения',
            'RefferalsPage.invites': 'пользователей',
            'RefferalsPage.description': 'Ты заработал',
            'RefferalsPage.noInvites': 'Хэй! Ты так и не пригласил ни одного друга!',
            'really.title': 'Играйте на своем мобильном телефоне',
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "ru", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;