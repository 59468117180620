import { useTranslation } from "react-i18next";
import QR from "../assets/images/QR.webp";

export const Really = () => {
    const { t } = useTranslation();

    
    return (
        <div
            className="transform-gpu relative w-full h-full flex flex-col items-center justify-evenly text-center"
        >
            <div className="will-change-[opacity] absolute top-[50%] left-[30%] w-3/5 h-3/5 z-0 bg-blur opacity-60 rounded-full blur-3xl animate-[ping_2s_3s_infinite]"></div>
            <h1 className="text-3xl">{t('really.title')}</h1>
            <img className="w-full mx-2" src={QR} alt="QR" />
        </div>
    );
}