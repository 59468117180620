import { useTranslation } from "react-i18next";
import UserAdd from '../../assets/images/user-cirlce-add.webp'
import { initUtils, retrieveLaunchParams } from "@telegram-apps/sdk-react";
import { IRefferalLink } from "../../api/interfaces";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { API_URL } from "../../api/hooks";


export const ShareButton = () => {
    const { t, i18n } = useTranslation();
    const utils = initUtils();
    const [shareData, setShareData] = useState<IRefferalLink>();

    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    const token = `tma ${initDataRaw}`;

    useEffect(() => {
        if (!shareData) {
            axios.get<IRefferalLink>(
                `${API_URL}refferal/share`,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((response) => {
                setShareData(response.data)
            }).catch((error) => {
                throw error
            })
        }
    }, [shareData, token]);

    return (
        <>
            {
                shareData &&
                <button onClick={() => utils.shareURL(shareData.refferal_link, i18n.language === 'ru' ? shareData.text_ru : shareData.text_en)} className="mt-[2.5rem] mb-[1.2rem] flex items-center justify-center bg-gradient-to-b from-sub-gradient-1 to-sub-gradient-2 py-[1.125rem] px-[3.875rem] rounded-xl -skew-x-3">
                    {t("InviteFriends")}
                    <img className="ml-1 h-[1.125rem]" src={UserAdd} alt="UserAdd" />
                </button>
            }
        </>
    )
}