import axios from "axios";

import * as Sentry from "@sentry/react";

import { IBalance, IGameButtons, IGameLevels, IRefferalFriends, IRefferalLink, ITop } from "./interfaces";
import { IGameButton } from "../game/interfaces";
import { useMemo } from "react";
import { retrieveLaunchParams } from "@telegram-apps/sdk-react";

export const API_URL = 'https://api.test.crazysausage.ru/v1/game/';

/**
 * Hook to generate an API object that can be used to interact with the API.
 * @param initDataRaw Raw game data which will be used to generate a token.
 * @returns An object with methods to interact with the API.
 * @throws Error if token is missing in the response.
 */
export const useApi = () => {
    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    if (!initDataRaw) {
        throw new Error("initDataRaw is missing");
    }
    const token = `tma ${initDataRaw}`;
    return {
        buttons: () => {
            return axios.get<IGameButtons>(`${API_URL}buttons`, {
                headers: {
                    Authorization: token
                }
            }).then(res => res.data).catch(err => {
                Sentry.captureException(err);
                throw err;
            });
        }
    }
}
