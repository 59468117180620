import { createSlice } from "@reduxjs/toolkit";
import { IGameButton } from "./game/interfaces";
import { IEvent, IGameLevel } from "./game/interfaces";

const eventSlice = createSlice({
    name: "event",
    initialState: {
        currentEvent: null as IEvent | null,
    },
    reducers: {
        setEvent: (state, action) => {
            state.currentEvent = action.payload;
        },
    },
});

const scoreSlice = createSlice({
    name: "score",
    initialState: {
        balance: 0,
    },
    reducers: {
        setScore: (state, action) => {
            state.balance = action.payload;
        },
    },
});

const myPhotoUrlSlice = createSlice({
    name: "myPhotoUrl",
    initialState: {
        photoUrl: null as string | null,
    },
    reducers: {
        setMyPhotoUrl: (state, action) => {
            state.photoUrl = action.payload;
        },
    },
});

const buttonsSlice = createSlice({
    name: "buttons",
    initialState: {
        buttons: [] as IGameButton[],
    },
    reducers: {
        setButtons: (state, action) => {
            state.buttons = action.payload;
        },
    },
});

const levelsSlice = createSlice({
    name: "levels",
    initialState: {
        levels: [] as IGameLevel[],
    },
    reducers: {
        setLevels: (state, action) => {
            state.levels = action.payload;
        },
    },
});

export const eventReducer = eventSlice.reducer;
export const scoreReducer = scoreSlice.reducer;
export const myPhotoUrlReducer = myPhotoUrlSlice.reducer;
export const buttonsReducer = buttonsSlice.reducer;
export const levelsReducer = levelsSlice.reducer;

export const { setEvent } = eventSlice.actions;
export const { setScore } = scoreSlice.actions;
export const { setMyPhotoUrl } = myPhotoUrlSlice.actions;
export const { setButtons } = buttonsSlice.actions;
export const { setLevels } = levelsSlice.actions;

