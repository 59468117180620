import './ProgressBar.styles.css';
import { motion } from "framer-motion";


export const ProgressBar = ({ data }: { data: {
    min: number;
    max: number;
    value: number;
} }) => {
    return (
        <motion.div className="transform-gpu w-full h-[0.438rem] bg-bg-progress mx-2 rounded-[3px] flex flex-col justify-center items-start p-[0.125rem]">
            <motion.div
                className="value-line w-full h-[0.188rem] bg-gradient-to-r from-sub-gradient-1 to-sub-gradient-2 rounded-[1.5px] transition-all duration-300 ease-in-out"
                style={{ width: `${Math.min((data.value / data.max) * 100, 100)}%` }}
            ></motion.div>
        </motion.div>
    );
};
