import { configureStore } from '@reduxjs/toolkit'
import { eventReducer, scoreReducer, myPhotoUrlReducer, buttonsReducer, levelsReducer } from './reducers'


export const store = configureStore({
  reducer: {
    event: eventReducer,
    score: scoreReducer,
    myPhotoUrl: myPhotoUrlReducer,
    buttons: buttonsReducer,
    levels: levelsReducer,
    prevEvent: eventReducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch