import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";


export const ErrorComponent = () => {
    const { t } = useTranslation();
    const error = useRouteError();
    Sentry.captureException(error);

    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="text-3xl mb-6 text-center w-full">{t('error.title')}</h1>
            <span className="text-lg my-4 text-center w-full">
                {t('error.message')}
            </span>
            {/* <p className="text-lg my-4">
                Need start in Telegram. Click <a className="underline text-blue-500" href="https://t.me/DRAMIObot/game">here</a>.
            </p> */}
        </div>
    );
}