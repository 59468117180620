import { useBackButton, useHapticFeedback, retrieveLaunchParams } from "@telegram-apps/sdk-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import RefferalsImg from "../assets/images/buttons/invites.png";
import { ShareButton } from "../components/UI/ShareButton";
import { IEventsState, IRefferalFriends } from "../api/interfaces";
import axios from "axios";
import SetPoints from '../assets/images/SetPoints.webp'
import { API_URL } from "../api/hooks";
import { Temporal } from "@js-temporal/polyfill";
import { CountDownTimer } from "../components/UI/Timer";



export const RefferalsPage = () => {

    const { t } = useTranslation();

    const backButton = useBackButton();
    const hapticFeedback = useHapticFeedback();
    const navigate = useNavigate();

    useEffect(() => {
        backButton.show();
        backButton.on('click', () => {
            hapticFeedback?.notificationOccurred('success');
            navigate('/menu')
            backButton.hide();
        })
    }, []);

    const [refferalFriendsData, setRefferalFriendsData] = useState<IRefferalFriends>();

    const [eventState, setEventState] = useState<IEventsState>();

    const [endDtEvent, setEndDtEvent] = useState<Temporal.ZonedDateTime>();

    useEffect(() => {
        if (eventState?.current_event) {

            const duration = Temporal.Duration.from(eventState.current_event?.duration);
            const startDtPlain = Temporal.Instant.from(eventState.current_event?.start_dt.toString());
            const endDt = startDtPlain.toZonedDateTime(
                {
                    timeZone: 'Europe/Moscow',
                    calendar: 'iso8601'
                }
            ).add(duration);
            setEndDtEvent(endDt);
        }
    }, [eventState?.current_event]);

    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    const token = `tma ${initDataRaw}`;

    useEffect(() => {
        if (!refferalFriendsData) {
            axios.get<IRefferalFriends>(
                `${API_URL}refferal/invited`,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((response) => {
                setRefferalFriendsData(response.data)
            }).catch((error) => {
                throw error
            })
        }
    }, [refferalFriendsData, token]);

    useEffect(() => {
        if (!eventState) {
            axios.get<IEventsState>(
                `${API_URL}events/state`,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((response) => {
                setEventState(response.data)
                console.log(response.data)
            }).catch((error) => {
                throw error
            })
        }
    }, [eventState, token])

    return (
        <div className="h-screen w-screen transform-gpu relative flex flex-col items-center justify-center">
            <div className="will-change-[opacity] absolute top-[50%] left-[30%] w-3/5 h-3/5 z-0 bg-blur opacity-60 rounded-full blur-3xl animate-[ping_2s_3s_infinite]"></div>
            <header className="w-full">
                <h1 className="mt-[2.3rem] mb-[.5rem] flex items-center justify-center">
                    <img className="w-[2.793rem] h-[2.793rem] mr-2" src={RefferalsImg} alt="Refferals" />
                    {t('RefferalsPage.title')}
                </h1>
                <div className="text-sm text-opacity-70 text-center tracking-wide mb-[.6rem] my-4">{refferalFriendsData ? refferalFriendsData?.count : 0} {t('RefferalsPage.invites')}</div>
                {refferalFriendsData && refferalFriendsData.refferal_balance > 0 && <>
                    <div className="my-2 text-center">{t('RefferalsPage.description')}</div>
                    <div className="text-sm font-video text-center tracking-wide text-add-coins mb-[.6rem]">
                        {refferalFriendsData?.refferal_balance}
                    </div>
                </>}
            </header>
            <div className="grow w-full mt-2 flex flex-col items-center first:mt-3 last:mb-3 overflow-y-scroll scroll-smooth">
                {!refferalFriendsData && <div className="flex items-center justify-center">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                        role="status">
                        <span

                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                        >
                    </div>
                </div>}
                {refferalFriendsData && refferalFriendsData?.refferal_friends.length === 0 &&
                    <div className="h-full grow text-center w-full flex flex-col items-center justify-center">
                        <div className="text-center">
                            {t('RefferalsPage.noInvites')}
                        </div>
                        <div className="mt-[1.5rem] timer uppercase">
                            {endDtEvent && <CountDownTimer endDtEvent={endDtEvent} />}
                        </div>
                        <div className="text-center tracking-wide font-semibold mt-[0.25rem]">
                            {t("About.Text7")}
                        </div>
                        <img className="mt-[-2.5rem] h-[10rem] mx-auto" src={SetPoints} alt="Set points" />
                        <div className="flex text-center tracking-wide font-semibold mt-[-3.5rem] items-center">
                            <div className="refferals-coins uppercase text-center">1000</div>
                            <div className="ml-1">{t("About.Text8")}</div>
                        </div>
                    </div>
                }
                {refferalFriendsData?.refferal_friends.map((friend, index) => (
                    <div
                        key={`leader-${friend.refferal_friend.first_name}-${friend.refferal_friend.last_name}`} className={`menu-button py-2 h-[4.2rem] ${!friend.is_active ? 'opacity-60 pointer-events-none' : ''}`}
                    >
                        <div className="mr-2">{index + 1}.</div>
                        {!friend.refferal_friend.photo_base64 && <div data-name={`${friend.refferal_friend.first_name[0].toUpperCase()} ${friend.refferal_friend.last_name ? friend.refferal_friend.last_name[0].toUpperCase() : ''}`} className="not-avatar h-[2.7rem] w-[2.7rem] bg-gradient-to-b from-[#1f2431] to-[#0b0d12] shadow-sm mr-[0.625rem] skew-x-[3deg]"></div>}
                        {friend.refferal_friend.photo_base64 && <img className="h-[2.7rem] w-[2.7rem] mr-[0.625rem] rounded-full skew-x-[3deg]" src={`data:image/png;base64,${friend.refferal_friend.photo_base64}`} alt="avatar" />}
                        <div className="mx-1">{friend.refferal_friend.first_name} {friend.refferal_friend.last_name}</div>

                        <div className="ml-auto">{friend.refferal_friend.balance.toLocaleString('en-US',
                            {
                                unitDisplay: 'long',
                                notation: 'compact',
                            })}</div>
                    </div>
                ))}
            </div>
            <ShareButton />
        </div>
    );
}