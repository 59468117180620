import { useHapticFeedback } from "@telegram-apps/sdk-react";
import { useNavigate } from "react-router-dom";

import Icon from '../../assets/images/menu-1.svg'

import './MenuButton.styles.css'

export const MenuButton = () => {
    const navigate = useNavigate();
    const hapticFeedback = useHapticFeedback();
    
    return (
        <div onClick={() => { 
            navigate('/menu')
            hapticFeedback?.notificationOccurred('success');
         }} className="menu-btn rounded-2xl bg-gradient-to-b from-[#1f2431] to-[#0b0d12] shadow-2xl border-t-2 ml-auto border-t-gray-600 border-b-black items-center justify-center grid grid-cols-2 px-[.4rem] p-1 cursor-pointer">
            {/* <img className="w-[0.776rem] h-[0.749rem]" src={Icon} alt="menu" /> */}
            {Array(4).fill(null).map((_, index) => (
                <div key={index} className={`w-2 h-2 rounded-full m-[0.3rem] ${index % 3 === 0 ? 'bg-[#1cb7dd]' : 'bg-gray-600'}`}></div>
            ))}
            
        </div>
    )
}