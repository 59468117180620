import { createBrowserRouter } from "react-router-dom";
import { Game } from "../pages/game";
import { LoadingPage } from "../pages/loading";
import { ErrorComponent } from "../pages/error";
import { MenuPage } from "../pages/menu";
import Characters from "../pages/characters";
import AboutPage from "../pages/about";
import { SettingsPage } from "../pages/settings";
import { NotFound } from "../pages/notFound";
import Levels from "../pages/levels";
import { LeadersPage } from "../pages/leaders";
import { RefferalsPage } from "../pages/refferals";
import { Really } from "../pages/really";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LoadingPage />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/game",
    element: <Game />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/menu",
    element: <MenuPage />,
    errorElement: <ErrorComponent />
  },
  {
    path: "/about",
    element: <AboutPage />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/characters",
    element: <Characters />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/levels",
    element: <Levels />,
    errorElement: <ErrorComponent />
  },
  {
    path: "/leaders",
    element: <LeadersPage />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/invites",
    element: <RefferalsPage/>,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/really",
    element: <Really/>,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/error",
    element: <ErrorComponent />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "*",
    element: <NotFound />,
    errorElement: <ErrorComponent />,
  }
],
  {

  }
);