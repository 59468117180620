import { useEffect, useMemo, useState } from "react";
import LevelsImg from "../assets/images/buttons/levels.png";

import { useTranslation } from "react-i18next";
import { IGameLevel } from "../game/interfaces";
import { retrieveLaunchParams, useBackButton, useHapticFeedback } from "@telegram-apps/sdk-react";
import axios from "axios";
import { API_URL } from "../api/hooks";
import Star from "../assets/images/Star.webp";
import { useNavigate } from "react-router-dom";


export default function Levels() {

    const { t, i18n } = useTranslation();

    const [levels, setLevels] = useState<Array<IGameLevel>>();

    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    const token = `tma ${initDataRaw}`;

    const backButton = useBackButton();
    const hapticFeedback = useHapticFeedback();
    const navigate = useNavigate();
    useEffect(() => {
        backButton.show();
        backButton.on('click', () => {
            hapticFeedback?.notificationOccurred('success');
            navigate('/menu')
            backButton.hide();
        })
    }, []);

    useEffect(() => {
        if (!levels) {
            axios.get<Array<IGameLevel>>(
                `${API_URL}levels/list`,
                // {
                //     headers: {
                //         Authorization: token
                //     }
                // }
            ).then((response) => {
                setLevels(response.data.sort((a, b) => a.score - b.score))
            }).catch((error) => {
                throw error
            })
        }
    }, [levels, token]);

    return (
        <div className="transform-gpu w-full relative flex flex-col items-center justify-center">
            {/* <div className="will-change-[opacity] absolute top-[50%] left-[30%] w-3/5 h-3/5 z-0 bg-blur opacity-60 rounded-full blur-3xl animate-[ping_2s_3s_infinite]"></div> */}
            <header className="w-full">
                <h1 className="my-[2.3rem] flex items-center justify-center">
                    <img className="w-[2.793rem] h-[2.793rem] mr-2" src={LevelsImg} alt="Levels" />
                    {t('MenuPage.levels')}
                </h1>
                <div className="w-full flex items-center justify-between text-sm font-video tracking-wide text-border-progress/70 px-[1.9rem] mb-3">
                    <div>{t('LevelsPage.level')}</div>
                    <div className="pl-11">{t('LevelsPage.score')}</div>
                    <div>{t('LevelsPage.bonus')}</div>
                </div>
            </header>
            <div className="grow w-full flex flex-col items-center first:mt-3 last:mb-3">
                {!levels && <div className="flex items-center justify-center">
                    {/* <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                        role="status">
                        <span

                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                        >
                    </div> */}

                </div>}
                {levels?.map((level) => (
                    <div
                        key={level.id}
                        className="menu-button items-center justify-items-end grid grid-cols-3"
                    >
                        <div className="justify-self-start flex items-center justify-center">
                            <img src={require(`../assets/images/badge/Badge${level.number}.webp`)} alt="Badge" className="w-[1.813rem] h-[2.377rem] mr-2" />
                            <div className={`text-xs font-light`} style={{ color: level.color }}>{i18n.language === 'ru' ? level.level_ru : level.level_en}</div>
                        </div>

                        <div className={`font-bold text-center`}>{level.score}</div>
                        <div className="flex">
                            <img
                                src={Star}
                                alt="Star"
                                className='mr-2 transition-all duration-300 ease-in-out' height={'15px'}
                                width={'15px'}
                            />
                            {level.bonus_score}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
