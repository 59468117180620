import { initPopup, retrieveLaunchParams } from "@telegram-apps/sdk-react";
import "./GameHead.styles.css";

import { MenuButton } from "./UI/MenuButton";
import { ProgressBar } from "./UI/ProgressBar";

import Star from "../assets/images/Star.webp";
import { AddCoinElement } from "./UI/AddCoin";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { IGameLevel } from "../game/interfaces";
import { motion } from "framer-motion";


interface IGameHeadProps {
    userCoins: number,
    addCoinsStack: Array<number>,
    setAddCoinsStack: React.Dispatch<React.SetStateAction<Array<number>>>
    photoBase64?: string | null | undefined
    currentLevel: IGameLevel | undefined
    prevLevelMaxCoins: number
    needNextLevelCoins: number
}


export const GameHead = ({ userCoins, addCoinsStack, setAddCoinsStack, photoBase64, currentLevel, prevLevelMaxCoins, needNextLevelCoins }: IGameHeadProps) => {
    const initData = retrieveLaunchParams();

    

    const popup = initPopup();
    const { t, i18n } = useTranslation();

    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    const token = `tma ${initDataRaw}`;

    

    return (
        <header className="w-screen flex flex-col px-5 mt-4">
            <div className="flex w-full items-center">
                {!photoBase64 && <div data-name={`${initData.initData?.user?.firstName[0].toUpperCase()} ${initData.initData?.user?.lastName ? initData.initData?.user?.lastName[0].toUpperCase() : ''}`} className="not-avatar h-14 w-14 bg-gradient-to-b from-[#1f2431] to-[#0b0d12] shadow-sm mr-[0.625rem] "></div>}
                {photoBase64 && <img className="h-14 w-14 mr-[0.625rem] rounded-full" src={`data:image/png;base64,${photoBase64}`} alt="avatar" />}
                <div className="flex flex-col justify-evenly">
                    <code><motion.h1 className="head-2">{initData.initData?.user?.firstName} {initData.initData?.user?.lastName}</motion.h1></code>
                    <motion.code onClick={() => {
                        popup.open({
                            title: t('GameHead.levelTitle'),
                            message: t('GameHead.levelMessage'),
                            buttons: [{ id: 'ok', type: 'ok' }],
                        })
                    }} style={{ color: currentLevel?.color }}>{i18n.language === 'ru' ? currentLevel?.level_ru : currentLevel?.level_en}</motion.code>
                </div>
                <MenuButton />
            </div>
            <div className="flex items-center mt-3 transform-gpu">
                <motion.code className="opacity-40">{prevLevelMaxCoins}</motion.code>
                <ProgressBar data={{ min: 0, max: needNextLevelCoins, value: userCoins }} />
                <motion.code className="opacity-40">{needNextLevelCoins.toLocaleString('en-US',
                    {
                        unitDisplay: 'long',
                        notation: 'compact',
                    })
                }</motion.code>
            </div>
            <div className="w-full flex items-center justify-between mt-4 mb-1">
                <motion.div className="h-4 w-7 rounded-xl bg-gradient-to-b from-[#1f2431] to-[#0b0d12] shadow-sm border-t-[#1a5971] border-t-2"></motion.div>
                <div className="flex items-center">
                    <motion.img
                        src={Star}
                        alt="Star"
                        className='will-change-[filter,drop-shadow] mr-2 transition-all duration-300 ease-in-out drop-shadow-coin' height={'30px'}
                        width={'30px'}
                    />
                    <motion.code
                        onClick={() => {
                            popup.open({
                                title: t('GameHead.pointsTitle'),
                                message: t('GameHead.pointsMessage'),
                                buttons: [{ id: 'ok', type: 'ok' }],
                            })
                        }}
                        className="transition-all duration-300 ease-in-out drop-shadow-coin text-2xl">{userCoins.toLocaleString(i18n.language === 'ru' ? 'ru-RU' : 'en-US',)}
                    </motion.code>
                    <div className="flex flex-col-reverse items-center relative transition-all duration-300 ease-in-out will-change-contents">
                        {addCoinsStack.length > 0 && (
                            <motion.div
                                key={addCoinsStack.toString()}
                                className="absolute"
                                style={{
                                    zIndex: 10,
                                    transform: 'translateX(60%) translateY(50%)',
                                }}
                            >
                                {addCoinsStack.map((value, index) => (
                                    <AddCoinElement
                                        key={new Date().getTime() + index}
                                        number={value}
                                    />
                                ))}
                            </motion.div>
                        )}
                    </div>
                </div>
                <motion.div className="h-4 w-7 rounded-xl bg-gradient-to-b from-[#1f2431] to-[#0b0d12] shadow-sm border-t-[#1a5971] border-t-2"></motion.div>
            </div>
        </header>
    );
}