import { initMiniApp, retrieveLaunchParams, useBackButton, useHapticFeedback } from "@telegram-apps/sdk-react";
import axios from "axios";

import LeadersImg from "../assets/images/buttons/leaders.png";

import TonLogo from "../assets/images/ton_symbol.svg";
import NotLogo from "../assets/images/not_symbol.svg";

import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../api/hooks";
import { IEventsState, ITop } from "../api/interfaces";

export const LeadersPage = () => {

    const { t } = useTranslation();

    const backButton = useBackButton();
    const hapticFeedback = useHapticFeedback();
    const navigate = useNavigate();

    useEffect(() => {
        backButton.show();
        backButton.on('click', () => {
            hapticFeedback?.notificationOccurred('success');
            navigate('/menu')
            backButton.hide();
        })
    }, []);

    const [miniApp] = initMiniApp();
    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    const token = `tma ${initDataRaw}`;

    const [leaders, setLeaders] = useState<ITop>();

    useEffect(() => {
        miniApp.setBgColor('#070b13');
        if (!leaders) {
            axios.get<ITop>(
                `${API_URL}events/current/users/top`,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((response) => {
                let leadersData: ITop = response.data

                if (leadersData.my_position > 10) {
                    leadersData.users.push(leadersData.me)
                }

                setLeaders(response.data)
            }).catch((error) => {
                throw error
            })
        }
    }, [leaders, miniApp, token]);

    const [eventState, setEventState] = useState<IEventsState>();

    useEffect(() => {
        if (!eventState) {
            axios.get<IEventsState>(
                `${API_URL}events/state`,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((response) => {
                setEventState(response.data)
                console.log(response.data)
            }).catch((error) => {
                throw error
            })
        }
    }, [eventState, token]);

    const [poolOnTon, setPoolOnTon] = useState<number>(0);
    const [poolOnNot, setPoolOnNot] = useState<number>(0);

    useEffect(() => {
        if (eventState) {
            let ton = 0;
            let not = 0;
            if (eventState.current_event?.gifts) {
                for (let i = 0; i < eventState.current_event?.gifts.length; i++) {
                    ton += eventState.current_event?.gifts[i].is_on_ton ? Number(eventState.current_event?.gifts[i].name) : 0
                    not += eventState.current_event?.gifts[i].is_on_ton ? 0 : Number(eventState.current_event?.gifts[i].name)
                }

                setPoolOnTon(ton)
                setPoolOnNot(not)
            }

        }
    }, [eventState]);

    return (
        <div className="h-screen w-screen transform-gpu relative flex flex-col items-center justify-center overflow-y-scroll">
            <div className="will-change-[opacity] absolute top-[50%] left-[30%] w-3/5 h-3/5 z-0 bg-blur opacity-60 rounded-full blur-3xl animate-[ping_2s_3s_infinite]"></div>
            <header className="w-full">
                <h1 className="mt-[2.3rem] mb-[.5rem] flex items-center justify-center">
                    <img className="w-[2.793rem] h-[2.793rem] mr-2" src={LeadersImg} alt="Characters" />
                    {t('MenuPage.leaders')}
                </h1>
                <div className="text-sm text-opacity-70 text-center tracking-wide mb-[.6rem]">Online</div>
                <div className="text-sm font-video text-center tracking-wide text-add-coins mb-[.6rem]">
                    {t('LeaderPage.prizePool')}
                </div>
                <div className="flex items-center justify-center mb-[1.4rem]">
                    {
                        poolOnTon > 0 &&
                        <div className="flex items-center justify-between">
                            <img className="w-[2rem] h-[2rem] mr-1" src={TonLogo} alt="Ton" />
                            {poolOnTon.toLocaleString('en-US',
                                {
                                    unitDisplay: 'long',
                                    notation: 'compact',
                                })}
                            <div className="ml-1">TON</div>
                        </div>
                    }
                    {
                        (poolOnNot > 0 && poolOnTon > 0) &&
                        <div className="mx-2">
                            +
                        </div>
                    }
                    {
                        poolOnNot > 0 &&
                        <div className="flex items-center justify-between">
                            <img className="w-[2rem] h-[2rem] mr-1" src={NotLogo} alt="Not" />
                            {poolOnNot.toLocaleString('en-US',
                                {
                                    unitDisplay: 'long',
                                    notation: 'compact',
                                })}
                            <div className="ml-1">NOT</div>
                        </div>
                    }
                </div>
            </header>
            <div className="grow w-full mt-2 flex flex-col items-center first:mt-3 last:mb-3 scroll-smooth">
                {!leaders && <div className="flex items-center justify-center">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                        role="status">
                        <span

                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                        >
                    </div>
                </div>}
                {leaders?.users.map((leader, index) => (
                    <div
                        key={`leader-${leader.first_name}-${leader.last_name}`} className="menu-button py-2 h-[4.2rem]"
                    >
                        {leaders.my_position > 10 && index === leaders.users.length - 1 && 
                            <div className="mr-2">{leaders.my_position}.</div>
                        }
                        {!(leaders.my_position > 10 && index === leaders.users.length - 1) && 
                            <div className="mr-2">{index + 1}.</div>
                        }
                        {!leader.photo_base64 && <div data-name={`${leader.first_name[0].toUpperCase()} ${leader.last_name ? leader.last_name[0].toUpperCase() : ''}`} className="not-avatar h-[2.7rem] w-[2.7rem] bg-gradient-to-b from-[#1f2431] to-[#0b0d12] shadow-sm mr-[0.625rem] skew-x-[3deg]"></div>}
                        {leader.photo_base64 && <img className="h-[2.7rem] w-[2.7rem] mr-[0.625rem] rounded-full skew-x-[3deg]" src={`data:image/png;base64,${leader.photo_base64}`} alt="avatar" />}
                        <div className="mx-1">{leader.first_name} {leader.last_name}</div>
                        {
                            eventState?.current_event?.gifts[index] &&
                            <div className="grow flex items-center justify-center">
                                {isNaN(Number(eventState?.current_event?.gifts[index].name)) ? eventState?.current_event?.gifts[index].name : Number(eventState?.current_event?.gifts[index].name).toLocaleString('en-US',
                                    {
                                        unitDisplay: 'long',
                                        notation: 'compact',
                                    })}
                                {
                                    eventState?.current_event?.gifts[index].is_on_ton
                                        ? <img className="w-[1.5rem] h-[1.5rem] mx-1" src={TonLogo} alt="Ton" />
                                        : <img className="w-[1.5rem] h-[1.5rem] mx-1" src={NotLogo} alt="Not" />
                                }
                            </div>
                        }
                        <div className="ml-auto">{isNaN(leader.balance) ? "0" : leader.balance.toLocaleString('en-US',
                            {
                                unitDisplay: 'long',
                                notation: 'compact',
                            })}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

