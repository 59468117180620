interface AddCoinElementProps {
    number: number;
    className?: string;
    style?: React.CSSProperties;
}

export const AddCoinElement: React.FC<AddCoinElementProps> = ({
    number,
    className,
    style,
}) => {
    return (
        <>
            {number !== 0 &&
                <span
                    className={`text-lg font-video tracking-tight font-bold text-add-coins uppercase animate-add-coin [animation-delay:_0.3s] [animation-fill-mode:_both] [animation-timing-function:_cubic-bezier(0.25,0.1,0.25,1)] ${className}`}
                    style={style}
                >
                    {number > 0 ? '+' : ''}
                    {number.toLocaleString('en-US', {
                        unitDisplay: 'long',
                        notation: 'compact',
                    })}
                </span>
            }
        </>
    )
}
