import { useViewport } from "@telegram-apps/sdk-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


import WifiOff from "../assets/images/wifi-off.png";

/**
 * A wrapper component that can be used to wrap any number of child components.
 * 
 * @param props The props object that contains the children components.
 * @returns The wrapped children components.
 */
export const Wrapper: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren): JSX.Element => {

    const vp = useViewport();
    const { t } = useTranslation();

    const [hight, setHight] = useState<number>(vp?.stableHeight ? vp?.stableHeight : window.innerHeight);
    const [width, setWidth] = useState<number>(vp?.width ? vp?.width : window.innerWidth);

    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    useEffect(() => {
        setHight(vp?.stableHeight ? vp?.stableHeight : window.innerHeight);
        setWidth(vp?.width ? vp?.width : window.innerWidth);
        
    }, [vp?.stableHeight, vp?.width]);

    return (
        <main className={`min-w-[${width}px] min-h-screen flex flex-col justify-center items-center relative`}>
            {children}
            <div className={`absolute ${isOnline ? '-bottom-20' : 'bottom-5'} mx-auto text-center rounded-lg shadow bg-[#114b88] w-[90%] flex items-center justify-center h-[3.563rem] animate-pong transition-all duration-300 ease-in-out`}>
                    <img className="w-[0.875rem] mr-2" src={WifiOff} alt="WifiOff" />
                    {t('error.offline')}
            </div>
        </main>
    )
}
