import { useTranslation } from "react-i18next";

import CharactersImg from "../assets/images/buttons/characters.png";
import { initMiniApp, retrieveLaunchParams, useBackButton, useHapticFeedback } from "@telegram-apps/sdk-react";
import axios from "axios";
import { useMemo, useEffect, useState } from "react";
import { API_URL } from "../api/hooks";
import { IGameButton } from "../game/interfaces";


import './characters.styles.css'
import { useNavigate } from "react-router-dom";


export default function Characters() {

    const { t } = useTranslation();

    const [buttons, setButtons] = useState<Array<IGameButton>>();
    const [miniApp] = initMiniApp();
    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    const token = `tma ${initDataRaw}`;

    const backButton = useBackButton();
    const hapticFeedback = useHapticFeedback();
    const navigate = useNavigate();
    useEffect(() => {
        backButton.show();
        backButton.on('click', () => {
            hapticFeedback?.notificationOccurred('success');
            navigate('/menu')
            backButton.hide();
        })
    }, []);

    useEffect(() => {
        miniApp.setBgColor('#070b13');
        if (!buttons) {
            axios.get<Array<IGameButton>>(
                `${API_URL}buttons`,
                // {
                //     headers: {
                //         Authorization: token
                //     }
                // }
            ).then((response) => {
                setButtons(response.data)
            }).catch((error) => {
                throw error
            })
        }
    }, [buttons, token]);

    return (
        <div className="w-full transform-gpu relative">
            <div className="will-change-[opacity] absolute top-[50%] left-[30%] w-3/5 h-3/5 z-0 bg-blur opacity-60 rounded-full blur-3xl animate-[ping_2s_3s_infinite]"></div>
            <header className="w-full">
                <h1 className="my-[2.3rem] flex items-center justify-center">
                    <img className="w-[2.793rem] h-[2.793rem] mr-2" src={CharactersImg} alt="Characters" />
                    {t('MenuPage.characters')}
                </h1>
                <div className="w-full flex items-center justify-between text-sm font-video tracking-wide text-border-progress/70 px-[2rem] mb-3">
                    <div>{t('CharacterPage.character')}</div>
                    <div>{t('CharacterPage.scores')}</div>
                    <div>{t('CharacterPage.rarity')}</div>
                </div>
            </header>
            <div className="w-full mt-3 flex flex-col items-center first:mt-3 last:mb-3 overflow-y-scroll scroll-smooth">
                {!buttons && <div className="flex items-center justify-center">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                        role="status">
                        <span

                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                        >
                    </div>
                </div>}
                {buttons?.sort((a, b) => b.score - a.score).map((button) => (
                    <div
                        key={button.id}
                        className="menu-button flex items-center justify-between mt-4"
                    >
                        <img
                            className={`relative ambient h-[4.542rem] -translate-y-[.5rem]`}
                            src={require(`../assets/images/characters/${button.name}.webp`)}
                            style={{ "--ambient": button.color } as React.CSSProperties}
                            alt={button.name} />
                        <div className={`grow text-center ${button.rarity < -1 ? '': 'mr-12'}`}>
                            {button.score}
                        </div>

                        <div className="">
                            {button.rarity === -1 && '∞'}
                            {button.rarity === -2 && `1 ${t('CharacterPage.per')} 10 с`}
                            {button.rarity === -3 && `1 ${t('CharacterPage.per')} 60 с`}
                            {button.rarity > 0 && button.rarity + '%'}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
