import { useTranslation } from "react-i18next";
import { API_URL } from "../api/hooks";
import { initBackButton, initPopup, retrieveLaunchParams, useHapticFeedback } from "@telegram-apps/sdk-react";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IGameButton } from "../game/interfaces";

import SetPoints from '../assets/images/SetPoints.webp'
import { ReactComponent as Bonus } from '../assets/images/Bonus.svg'
import { IEventsState } from "../api/interfaces";
import { Temporal } from "@js-temporal/polyfill";
import { CountDownTimer } from "../components/UI/Timer";
import { ShareButton } from "../components/UI/ShareButton";

export default function AboutPage() {
    const { t } = useTranslation();

    const [buttons, setButtons] = useState<Array<IGameButton>>();

    const [backButton] = initBackButton();
    const hapticFeedback = useHapticFeedback();
    const navigate = useNavigate();

    
    const [eventState, setEventState] = useState<IEventsState>();

    const [endDtEvent, setEndDtEvent] = useState<Temporal.ZonedDateTime>();

    useEffect(() => {
        if (eventState?.current_event) {

            const duration = Temporal.Duration.from(eventState.current_event?.duration);
            const startDtPlain = Temporal.Instant.from(eventState.current_event?.start_dt.toString());
            const endDt = startDtPlain.toZonedDateTime(
                {
                    timeZone: 'Europe/Moscow',
                    calendar: 'iso8601'
                }
            ).add(duration);
            setEndDtEvent(endDt);
        }
    }, [eventState?.current_event]);

    const popup = initPopup();

    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    
    const token = `tma ${initDataRaw}`;
    
    useEffect(() => {
        if (!buttons) {
            axios.get<Array<IGameButton>>(
                `${API_URL}buttons`,
                // {
                //     headers: {
                //         Authorization: token
                //     }
                // }
            ).then((response) => {
                setButtons(response.data)
            }).catch((error) => {
                throw error
            })
        }
    }, [buttons, token]);


    useEffect(() => {
        if (!eventState) {
            axios.get<IEventsState>(
                `${API_URL}events/state`,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((response) => {
                setEventState(response.data)
                console.log(response.data)
            }).catch((error) => {
                throw error
            })
        }
    }, [eventState, token]);

    useEffect(() => {
        backButton.show();
        backButton.on('click', () => {
            hapticFeedback?.notificationOccurred('success');
            navigate('/menu')
            backButton.hide();
        })
    }, []);

    return (
        <div className="transform-gpu h-full flex flex-col items-center w-full delay-300 transition-all ease-in-out relative scroll-smooth">
            <div className="will-change-[opacity] absolute top-[50%] left-[30%] w-3/5 h-3/5 z-0 bg-blur opacity-60 rounded-full blur-3xl animate-[ping_2s_3s_infinite]"></div>
            <div className="flex mb-[2.188rem] items-center text-center">
                <img className="h-[1.85rem]" src={require("../assets/images/TON.webp")} alt="TON" />
                <h1 className="text-3xl font-video text-center font-bold text-border-progress uppercase mx-[0.75rem]">{t("About.Title")}</h1>
                <img className="h-[1.85rem]" src={require("../assets/images/TON.webp")} alt="TON" />
            </div>
            <div className="flex text-xs text-nowrap text-center mb-[2.216rem]">
                <b>{t("About.Text1")}</b>{t("About.Text2")}<div className="ml-1 text-[#ffd338]">{t("About.Text3")}</div>
            </div>
            <div className="flex w-full justify-evenly">
                {!buttons && <div className="flex items-center justify-center">
                    <div
                        className="inline-block h-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                        role="status">
                        <span

                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                        >
                    </div>
                </div>}
                {buttons?.filter((button) => button.score > 0).sort((a, b) => a.score - b.score).map((button) => (
                    <div key={button.id} className={`menu-button h-[4.131rem] w-[4.313rem] relative`} onClick={() => {
                        popup.open({
                            title: t('About.givePointsPopupTitle'),
                            message: button.score + ' ' + t('About.givePointsPopupMessage'),
                            buttons: [{ id: 'ok', type: 'ok' }],
                        })
                    }}>
                        <img className="absolute h-[8rem] top-0 bottom-0 left-1/2 right-1/2 z-30 transform -translate-x-1/2 -translate-y-1/3 skew-x-3" src={require(`../assets/images/characters/${button.name}.webp`)} alt={button.name} />
                        <div className="absolute inset-0 flex items-center justify-center z-20">
                            <div className={`blur-xl rounded-full h-[3.125rem] w-[3.125rem]`} style={{ backgroundColor: button.color }} />
                        </div>
                        {button.name !== 'MainSausage' && <>
                            <Bonus className="absolute top-0 bottom-0 left-1/2 right-1/2 z-40 transform -translate-x-1/2 translate-y-[300%] h-[1.188rem]" />
                        </>}
                    </div>
                ))}
            </div>
            <div className="mt-[1.653rem] flex text-xs text-wrap text-center">
                {t("About.Text5")}
                <div className="text-[#ea2855] mx-1 mr-0">{t("About.Text4")}</div>{t("About.Text6")}
            </div>
            <div className="w-9/12 mt-[1.625rem] grid grid-cols-4 justify-evenly">
                {buttons?.filter((button) => button.score < 0).map((button) => (
                    <div key={button.id} className={`menu-button h-[3.188rem] w-[3.313rem] relative mx-auto`} onClick={() => {
                        popup.open({
                            title: t('About.delPointsPopupTitle'),
                            message: Math.abs(button.score) + ' ' + t('About.givePointsPopupMessage'),
                            buttons: [{ id: 'ok', type: 'ok' }],
                        })
                    }}>
                        <img className="absolute w-[5.2rem] top-0 bottom-0 left-1/2 right-1/2 z-30 transform -translate-x-1/2 -translate-y-1/4 skew-x-3" src={require(`../assets/images/characters/${button.name}.webp`)} alt={button.name} />
                        <div className="absolute inset-0 flex items-center justify-center z-20">
                            <div className={`blur-xl rounded-full h-[2rem] w-[2rem]`} style={{ backgroundColor: button.color }} />
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-[1.5rem] timer uppercase">
                {endDtEvent && <CountDownTimer endDtEvent={endDtEvent} />}
            </div>
            <div className="text-center tracking-wide font-semibold mt-[0.25rem]">
                {t("About.Text7")}
            </div>
            <img className="mt-[-2.5rem] h-[10rem] mx-auto" src={SetPoints} alt="Set points" />
            <div className="flex text-center tracking-wide font-semibold mt-[-3.5rem] items-center">
                <div className="refferals-coins uppercase">1000</div>
                <div className="ml-1">{t("About.Text8")}</div>
            </div>
            <ShareButton/>
        </div>
    );
}
