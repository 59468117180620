import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { SDKProvider } from '@telegram-apps/sdk-react';
import { SpeedInsights } from "@vercel/speed-insights/react"
import * as Sentry from "@sentry/react";
import { Analytics } from "@vercel/analytics/react"
import { RouterProvider } from 'react-router-dom';
import { Wrapper } from './utils/wrapper';

import './utils/i18n';
import { router } from './utils/router';
import { Provider } from 'react-redux';
import { store } from './store';
import { Helmet } from 'react-helmet';
import { API_URL } from './api/hooks';

Sentry.init({
  dsn: "https://8886774ad1aa4165036134c0aeb05185@o4507593276719104.ingest.de.sentry.io/4507593283534928",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <Helmet>
      <link rel="prefetch" href={`${API_URL}/buttons`} as='xhr'/>
      <link rel="prefetch" href={`${API_URL}/levels/list`} as='xhr' />
    </Helmet>
    <Provider store={store}>
      <SDKProvider acceptCustomStyles>
        <div className={`font-main bg-gradient-to-b from-bg-gradient-1 to-bg-gradient-2 text-default-white relative select-none overflow-hidden transition-all duration-300 ease-in-out cursor-default touch-none`}>
          <Wrapper>
            <RouterProvider router={router} />
          </Wrapper>
        </div>
      </SDKProvider>
    </Provider>
    <Analytics />
    <SpeedInsights framework='react' />
  </React.StrictMode>
);
reportWebVitals();
