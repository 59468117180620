import { useBackButton, useHapticFeedback } from "@telegram-apps/sdk-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {

    const backButton = useBackButton();
    const hapticFeedback = useHapticFeedback();
    const navigate = useNavigate();
    useEffect(() => {
        backButton.show();
        backButton.on('click', () => {
            hapticFeedback?.notificationOccurred('success');
            navigate('/menu')
            backButton.hide();
        })
    }, []);

    return (
        <div className="not-found">
            <h1>Not Found</h1>
            <p>Page not found</p>
        </div>
    );
}